import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserInvitation from '../../models/UserInvitation';
import { Option } from '../Option';
import InviteOrSearchContent from './InviteOrSearchContent';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';

type InviteOrSearchModalProps = {
  open: boolean;
  exisitingUsers?: Option<string, string>[];
  onClose: () => void;
  onAddExisiting?: (user: UserInvitation) => void;
  onInviteNew?: (user: UserInvitation) => void;
  filterRoles?: (role: Option<string, string>) => boolean;
  clientId: string | null | undefined;
};

const EMPTY_FILTER = () => true;

const InviteOrSearchModal: FC<InviteOrSearchModalProps> = (props) => {
  const { open, exisitingUsers, onClose, onAddExisiting, onInviteNew, filterRoles = EMPTY_FILTER, clientId } = props;

  const [inviteMode, setInviteMode] = useState(!exisitingUsers);
  const [inputsValid, setInputsValid] = useState(false);
  const [user, setUser] = useState<UserInvitation | null>(null);
  const { t } = useTranslation('common');

  const doInvite = useCallback(() => {
    if (!user) {
      return;
    }

    inviteMode ? onInviteNew && onInviteNew(user) : onAddExisiting && onAddExisiting(user);
  }, [inviteMode, onAddExisiting, onInviteNew, user]);

  return (
    <ModalContext.Provider value={{ open: open, onClose: onClose, modalWidth: 'w-1/4' }}>
      <StandardModal
        title={t('add-or-invite-modal.heading')}
        confirmButtonTitle={t('add-or-invite-modal.button')}
        onCancelClick={onClose}
        onConfirmClick={doInvite}
        confirmDisabled={!inputsValid}
        tertiaryButtonTitle={inviteMode ? t('add-or-invite-modal.switch.add') : t('add-or-invite-modal.switch.invite')}
        onTertiaryButtonClick={
          exisitingUsers
            ? () => {
                setInviteMode((prev) => !prev);
              }
            : undefined
        }
      >
        <InviteOrSearchContent
          inviteMode={inviteMode}
          filterRoles={filterRoles}
          onInputsValid={setInputsValid}
          onUserChange={setUser}
          exisitingUsers={exisitingUsers}
          clientId={clientId}
        />
      </StandardModal>
    </ModalContext.Provider>
  );
};

export default InviteOrSearchModal;
